<template>
    <div class="profissional">
      <page-with-footer>
        <profissional-component class="profissional-component"/>
        <section>
            <app-footer/>
        </section>
      </page-with-footer>
    </div>
</template>

<script>
import ProfissionalComponent from '@/components/Profissional/'
import Footer from '@/components shared/footer.vue'
import PageWithFooter from '@/components shared/page-with-footer.vue'
export default {
  name: 'Profissional',
  components: {
    'profissional-component': ProfissionalComponent,
    'app-footer': Footer,
    'page-with-footer': PageWithFooter
  },
  methods: {
    goto () {
      const ScreenSize = window.screen.width
      if (localStorage.getItem('@Rise_Parceiro_Continue_Activite2') === 'yes' && localStorage.getItem('@Rise_Parceiro_Profissional_TopTen') === 'yes') {
        window.scrollTo({ left: 0, top: 550, behavior: 'smooth' })
        if (ScreenSize < 768) {
          window.scrollTo({ left: 0, top: 500, behavior: 'smooth' })
        }
        localStorage.setItem('@Rise_Parceiro_Continue_Activite2', '')
        localStorage.setItem('@Rise_Parceiro_Profissional_TopTen', '')
      } else if (localStorage.getItem('@Rise_Parceiro_Continue_Activite2') === 'yes' && localStorage.getItem('@Rise_Parceiro_Profissional_Recents') === 'yes') {
        window.scrollTo({ left: 0, top: 1150, behavior: 'smooth' })
        if (ScreenSize < 768) {
          window.scrollTo({ left: 0, top: 1050, behavior: 'smooth' })
        }
        localStorage.setItem('@Rise_Parceiro_Continue_Activite2', '')
        localStorage.setItem('@Rise_Parceiro_Profissional_Recents', '')
      } else if (localStorage.getItem('@Rise_Parceiro_Continue_Activite2') === 'yes' && localStorage.getItem('@Rise_Parceiro_Profissional_Pagination') === 'yes') {
        window.scrollTo({ left: 0, top: 2050, behavior: 'smooth' })
        if (ScreenSize < 768) {
          window.scrollTo({ left: 0, top: 1550, behavior: 'smooth' })
        }
        localStorage.setItem('@Rise_Parceiro_Continue_Activite2', '')
        localStorage.setItem('@Rise_Parceiro_Profissional_Pagination', '')
      }
    }
  },
  mounted () {
    setTimeout(() => {
      this.goto()
    }, 2000)
  }
}
</script>

<style lang="scss" scoped>
    .profissional{
        width: 100%;
        height: auto;
        min-height: 100vh;
    }
    .profissional-component{
        @media(max-width:768px){
        }
    }
    .bg-botton::before{
      content: '';
      background: transparent url('../assets/img/RODAPE.svg') 0% 0% no-repeat padding-box;
      background-repeat: no-repeat;
      background-size:cover;
      position: absolute;
      width: 100%;
      height: 90%;
      display: block;
      z-index: -1;
      transform: scaleX(1.3) scaley(1.1) translateX(-10%);
  }
  .bg-botton{
      width: 100%;
      min-height: 1507px;
      height: auto;
      position: relative;
      display: flex;
      pointer-events: none;
      flex-direction: column;
      justify-content: flex-end;
      margin-bottom: 0px;
      @media(max-width:768px){
        height: auto!important;
        padding-bottom: 10px;
      }
  }
    @media(max-width: 768px){

   }
    @media(max-width: 768px){
      .bg-botton::before{
          height: 64%!important;
      }
   }
    @media(max-width: 1024px){
      .bg-botton::before{
          content: '';
          background: transparent url('../assets/img/RODAPE.svg') 0% 0% no-repeat padding-box;
          background-repeat: no-repeat;
          background-size:cover;
          position: absolute;
          width: 100%;
          max-width: 100vw;
          height: 65%;
          bottom: -20px!important;
          display: block;
          z-index: -2;
          left: -2%;
          transform: scaleX(auto) scaley(1.3) translateX(0);
      }
   }
    @media(max-width:2560px){
      .bg-botton::before{
          content: '';
          background: transparent url('../assets/img/RODAPE.svg') 0% 0% no-repeat padding-box;
          background-repeat: no-repeat;
          background-size:cover;
          position: absolute;
          width: 100%;
          max-width: 100vw;
          height: 100%;
          display: block;
          z-index: -2;
          left: 15%;
          transform: scaleX(auto) scaley(1.3) translateX(0);
      }
   }
  @media(max-width:1900px){
      .bg-botton::before{
        content: '';
        background: transparent url('../assets/img/RODAPE.svg') 0% 0% no-repeat padding-box;
        background-repeat: no-repeat;
        background-size:cover;
        position: absolute;
        width: 100%;
        height: 90%;
        display: block;
        z-index: -1;
        left: -2%;
        transform: scaleX(auto) scaley(1.3) translateX(0);
    }
  }
</style>
