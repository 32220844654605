<template>
  <div class="checkbox-animate">
    <label>
      <input type="checkbox" v-bind="$attrs"  v-on="$listeners">
      <span class="input-check"></span>
      <span>
        {{title}}
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'SidebarCheckbox',
  inheritAttrs: false,
  props: {
    title: String
  }
}
</script>

<style lang="scss" scoped>
    label{
        text-align: start;
    }
    .checkbox-animate {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 16px;
        z-index: 9;
        @media(max-width:768px){
            font-size: 12px;
            text-align: left;
            font-weight: 100!important;
        }
    }
    .checkbox-animate label {
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        margin: 10px 0;
    }
    .checkbox-animate label input {
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        pointer-events: none;
    }

    .input-check {
        display: inline-block;
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
        border-radius: 4px;
        border: 2px solid #ccc;
        position: relative;
        top:0;
        margin-right: 7px;
        transition: 0.4s;
        @media(max-width:768px){
          width: 35px;
          height: 35px;
          min-width: 35px;
          min-height: 35px;
          border: 1px solid #4B335A;
        }
    }

    .input-check::before {
        content: '';
        display: inline-block;
        width: 15px;
        height: 6px;
        border-bottom: 4px solid #fff;
        border-left: 4px solid #fff;
        transform: scale(0) rotate(-45deg);
        position: absolute;
        top: 6px;
        left: 4px;
        transition: 0.4s;
            @media(max-width:768px){
            width: 17px;
            height: 8px;
            top: 8px;
            left: 7px;
        }
    }
    .checkbox-animate label input:checked ~ .input-check {
    background-color: #342041;
    border-color: #342041;
    animation-name: input-animate;
    animation-duration: 0.7s;
    }

    .checkbox-animate label input:checked ~ .input-check::before {
    transform: scale(1) rotate(-45deg);
    animation-name: input-check;
    animation-duration: 0.2s;
    animation-delay: 0.3s;
    }

</style>
