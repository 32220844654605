<template>
  <div class="container" :class="{'show-filter': openFilter}">
    <base-container-wrapper-grid
      :arraySlideInfo="items"
      class="space-medium"
    >
    <header-profissional slot="header-title" class="margin-large">{{headerTitle}}</header-profissional>
    <template #swiper-card="{ item }">
      <card-item :item="item" @handler-profissional="$emit('handler-profissional-data', $event)" />
    </template>
    </base-container-wrapper-grid>
    <div class="container-pagination">
      <div class="pagination" v-if="pagination && pagesInfo && pagesInfo.totalPaginas > 1">
        <button class="btn" :class="{active: index + 1 === page}" @click="$emit('navigation-page', index + 1)" v-for="(number, index) in pagesInfo.totalPaginas" :key="index">{{number}}</button>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderGridProfissional from '@/components/Profissional/header-grid-profissional'
import BaseContainerWrapperGrid from '@/components/Home/base-container-wrapper-grid'
import CardItem from '@/components/Home/card'

export default {
  name: 'grid-profissional',
  components: {
    'base-container-wrapper-grid': BaseContainerWrapperGrid,
    'card-item': CardItem,
    'header-profissional': HeaderGridProfissional
  },
  props: {
    items: [Array, Object],
    openFilter: Boolean,
    headerTitle: {
      type: String,
      default: 'Title'
    },
    pagination: {
      type: Boolean,
      default: false
    },
    page: {
      type: Number,
      default: 1
    },
    pagesInfo: {
      type: Object,
      default: () => ({ totalPaginas: -1 })
    }
  }
}
</script>
<style lang="scss" scoped>
.container {
  margin: 60px 0;
  .header {
    margin: 30px 0;
  }
  &:first-child {
    margin-top: 0;
  }
  &.show-filter {
    max-width: 100%;
    margin: 0 auto;
  }
  .container-pagination {
    display: flex;
    justify-content: center;
  }
  .pagination {
    display: flex;
    margin-top: 30px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 600px;
    .btn {
      margin: 0 3px;
      cursor: pointer;
      border: 1px solid #ccc;
      background-color: #868686;
      border-radius: 4px;
      color: #fff;
      font-weight: 500;
      &.active {
        background-color: #107578;
      }
    }
  }
}
</style>
