<template>
  <div class="filter">
    <div role="button" class="btn-filter" @click="toggleFilter" :class="{large: openFilter}">
      <img src="@/assets/img/filter.svg" height="14" alt="icon filter profissional">
      <span>Filtros</span>
      <span class="btn-filter-arrow-forward">
        <img v-if="!openFilter" width="5" src="@/assets/img/arrow-forward.svg" alt="icon seta abrir">
        <img v-else width="5" src="@/assets/img/arrow-back.svg" alt="icon seta fechar">
      </span>
    </div>
    <div class="main-filter-title">
      <h1 class="">Minhas Pesquisas</h1>
    </div>
  </div>
</template>
<script>
export default {
  name: 'filter-profissional',
  props: {
    openFilter: Boolean
  },
  methods: {
    toggleFilter () {
      this.$emit('toggleFilter')
    }
  }
}
</script>

<style lang="scss" scoped>
.filter {
    height: 160px;
    display: flex;
    align-items: center;
    background-color: #26A0A5;
    color: #4B335A;
    font-size: 12px;
    .btn-filter {
      display: inline-flex;
      min-width: 130px;
      justify-content: space-between;
      align-items: center;
      background-color: #4B335A;
      color: #fff;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      cursor: pointer;
      padding-left: 12px;
      img, span {
        pointer-events: none;
      }
      &.large {
        width: 300px;
       span:not(.btn-filter-arrow-forward) {
         flex: 1;
         text-align: left;
         margin-left: 22px;
       }
      }
      .btn-filter-arrow-forward {
        padding: 18px 6px;
        border-left: 1px solid #26A0A5;
        align-self: stretch;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .main-filter-title {
      margin-left: 40px;
      h1 {
        font-size: 29px;
        @media screen and (min-width: 390px) {
          font-size: 40px;
        }
      }
      .hashtags {
        width: 50%;
      }
      .hashtag {
        margin: 0 2px;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
        cursor: pointer;
        &:active {
          opacity: 0.7;
          -webkit-user-select: none;
          -moz-user-select: none;
          user-select: none;
        }
        span {
          border-radius: 50px;
          padding: 0 4px;
          background-color: #D5D5D5;
        }
        button {
          width: 16px;
          height: 16px;
          position: relative;
          transform: rotate(45deg);
          display: inline-flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          border-radius: 50%;
          margin-right: -4px;
          border-style: none;
          color: #f00;
          cursor: pointer;
          background-color: #bebebe;
        }
      }
    }
  }
</style>
