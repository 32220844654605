<template>
  <div class="candidate-main">
      <div>
        <header>
          <h1>Encontre o seu candidato certo</h1>
          <div class="toobar-wrapper">
            <toobar-search class="toolbar-candidates" hide-employee="flex" hide-comapay="flex" input-background="#FACA63" />
          </div>
        </header>
        <filter-header @toggleFilter="openFilter = !openFilter" :openFilter="openFilter" />
        <div class="content-main">
          <div class="aside-filter" v-show="openFilter">
            <ul class="filters">
              <li class="filter">
                <strong>Sector</strong>
                <droplist
                  class="medium primary-color fs-small"
                  align="bottom"
                  placeholder="Seleciona um Sector"
                  :dataSource="sectores"
                  :value="sector"
                  @setData="setSector"
                />
              </li>
              <li class="filter functions">
                <strong>Função</strong>
                <ul class="checklist">
                  <li v-for="(item, index) in allFunctions" :key="index">
                  <sidebar-checkbox
                    :checked="arrayFuntions.includes(item.id)"
                    name="check"
                    :itemId="item.id"
                    @click="getIdFun(item.id)"
                    :title="item.nome"
                  />
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="wrapper-main" :class="{hiddenFilter: openFilter}">
            <container-card>
              <grid-profissional
              v-if="topTen.length"
              :items="topTen"
              :openFilter="openFilter"
              headerTitle="Mais visualizados"
              @handler-profissional-data="getProfissionalData"
              />
            </container-card>
            <container-card>
              <grid-profissional
              v-if="recents.length"
              :items="recents"
              :openFilter="openFilter"
              headerTitle="Mais recentes"
              @handler-profissional-data="getProfissionalData"
              />
            </container-card>
            <container-card>
              <grid-profissional
              v-if="allprofissionais.length"
              :items="allprofissionais"
              :openFilter="openFilter"
              :pagesInfo="pagesInfo"
              :page="page"
              :pagination="true"
              headerTitle="Todos"
              @navigation-page="page = $event"
              @handler-profissional-data="getProfissionalData"
              />
              <p v-else class="not-exist-profissional">Sem profissionais para a pesquisa definida.</p>
            </container-card>
          </div>
        </div>
      </div>
      <modal-details
        :id-from-list="modalData"
        v-if="showModal"
        @close="showModal = false"
      />
      <sign-in-out-modal
        v-if="confirmModal"
        @close="confirmModal = false"
        :modal-btn-continue="modalBtnContinue"
        :modal-title="modalText"
        :be-true="beTrue"
      />
  </div>
</template>

<script>
import { getTopTen, getRecents, getProfissionais } from '@/api/services/filters'
import { getFunctions } from '@/api/services/profissional'
import Toobar from '@/components shared/ToobarSearch/toobar-search'
import SelectDropdown from '@/components/Home/list-item'
import SidebarCheckbox from './SidebarCheckbox'
import { isAuthenticated, isAuthenticatedParceiro } from '@/utils/auth'
import ModalDetails from '@/components shared/Modal/ProfissionalModalDetails'
import SignInOutModal from '@/components shared/Modal/ConfirmModalSignOut'
import FilterHeader from '@/components/Profissional/filter'
import GridProfissional from '@/components/Profissional/grid-profissional'
import ContainerCard from '@/components shared/container-card'

import { getSectores } from '@/api/services/home-page'
export default {
  name: 'Candidate',
  mounted () {
    if (this.getSearchValue) {
      localStorage.setItem('@Rise_Search', '')
    }
    getSectores().then(res => res.data.object).then(data => {
      let values = Object.assign([], data)
      values.unshift({ nome: 'Todos' })
      this.sectores = values
      const filter = this.$route.query?.filter || ''
      if (filter) {
        const sector = values.find(item => item.nome.localeCompare(filter) === 0)
        this.setSector(sector)
      } else {
        this.setSector(values[0])
        this.getAllProfissionais()
      }
    })
    getFunctions()
      .then(res => res.data.object)
      .then(data => {
        this.allFunctions = data // .concat({ id: 99999, nome: 'Todas' })
      })
  },
  components: {
    'toobar-search': Toobar,
    'droplist': SelectDropdown,
    'sidebar-checkbox': SidebarCheckbox,
    'modal-details': ModalDetails,
    'sign-in-out-modal': SignInOutModal,
    'filter-header': FilterHeader,
    'grid-profissional': GridProfissional,
    'container-card': ContainerCard
  },
  methods: {
    toggleSidebar () {
      this.ActiveSideBar = !this.ActiveSideBar
    },
    getOtherProfissional (page = 1) {
      getProfissionais({ Id_func: this.arrayFuntions.filter(id => id !== 99999), id_sector: this.get_Id_Sector, search: '', page: page, take: 10 }).then(resp => {
        this.allprofissionais = resp.data.object
        this.pagesInfo = resp.data.pageInfo
      })
    },
    setSector (data) {
      this.sector = data.nome
      this.getIdSector(data.id)
    },
    getProfissionalData (id) {
      if (isAuthenticatedParceiro() && !isAuthenticated()) {
        this.showModal = true
        this.modalData = id
        this.AddView(id)
      } else if (!isAuthenticatedParceiro() && !isAuthenticated()) {
        this.modalText = 'Para ver detalhes do Profissional deve iniciar sessão como Parceiro. Deseja continuar?'
        this.modalBtnContinue = true
        this.confirmModal = true
        localStorage.setItem('@Rise_Parceiro_Home_Recents', 'yes')
      } else if (isAuthenticated() && !isAuthenticatedParceiro()) {
        this.modalText = 'Para ver detalhes do Profissional deve ter sessão iniciada como Parceiro. Tem certeza que pretender terminar a sessão actual?'
        this.modalBtnContinue = true
        this.confirmModal = true
        this.beTrue = false
        localStorage.setItem('@Rise_Parceiro_Home_Recents', 'yes')
      }
    },
    getFunction (id) {
      return this.allFunctions.find(item => item.id === id)
    },
    getAllProfissionais () {
      this.LoadingTopTen = true
      this.LoadingRecents = true
      this.LoadingProfissional = true
      getTopTen({ Id_func: this.arrayFuntions.filter(id => id !== 99999), id_sector: this.get_Id_Sector, search: this.getSearchValue }).then(resp => {
        this.topTen = resp.data.object
      }).finally(() => { this.LoadingTopTen = false })
      getRecents({ Id_func: this.arrayFuntions.filter(id => id !== 99999), id_sector: this.get_Id_Sector, search: this.getSearchValue }).then(resp => {
        this.recents = resp.data.object
      }).finally(() => { this.LoadingRecents = false })
      getProfissionais({ Id_func: this.arrayFuntions.filter(id => id !== 99999), id_sector: this.get_Id_Sector, search: this.getSearchValue, page: 1 }).then(resp => {
        this.allprofissionais = resp.data.object
        this.pagesInfo = resp.data.pageInfo
      }).finally(() => { this.LoadingProfissional = false })
    },
    getIdSector (id) {
      this.get_Id_Sector = id
      this.getAllProfissionais()
    },
    getIdFun (id) {
      if (id === 99999) {
        this.arrayFuntions.splice(0, this.arrayFuntions.length)
        this.arrayFuntions.push(id)
      } else if (this.arrayFuntions.includes(id)) {
        this.arrayFuntions.splice(this.arrayFuntions.indexOf(id), 1)
      } else {
        this.arrayFuntions.includes(99999) && this.arrayFuntions.splice(0, this.arrayFuntions.length)
        this.arrayFuntions.push(id)
      }
      this.getAllProfissionais()
    }
  },
  watch: {
    page: function (value) {
      this.getOtherProfissional(this.page)
    }
  },
  data () {
    return {
      openFilter: true,
      sectores: [],
      sector: '',
      page: 1,
      tags: [],
      showModal: false,
      verifyAuth: false,
      confirmModal: false,
      beTrue: true,
      modalText: '',
      modalBtnContinue: false,
      ActiveSideBar: false,
      LoadingTopTen: false,
      LoadingRecents: false,
      LoadingProfissional: false,
      showInfo: false,
      getSearchValue: localStorage.getItem('@Rise_Search') || '',
      modalData: '',
      get_Id_Function: 0,
      get_Id_Sector: 0,
      arrayFuntions: [],
      topTen: [],
      recents: [],
      allprofissionais: [],
      pagesInfo: { totalPaginas: -1 },
      allFunctions: [],
      id_area: 0
    }
  }
}
</script>

<style lang="scss" scoped>
.candidate-main {
  .content-main {
    min-height: 120vh;
    display: flex;
    justify-content: space-between;
    .aside-filter {
      border-right: 1px solid #26A0A5;
      padding-top: 20px;
      min-width: 100%;
      @media screen and (min-width: 768px){
        min-width: 261px;
        max-width: 280px;
      }
      .filters {
        width: 84%;
        height: 100%;
        max-height: 100%;
        margin: 0 auto;
        display: flex;
        list-style: none;
        padding-left: 0;
        flex-direction: column;
        color: #4B335A;
        .filter {
          margin: 12px 0;
          .checklist {
            list-style: none;
            padding-left: 0;
            max-height: 100%;
          }
        }
        .functions {
          flex: 1;
          max-height: 74vh;
          overflow-y: auto ;
        }
      }
    }
  }
}
  .wrapper-main {
    width: 100%;
     @media screen and (max-width: 600px) {
       &.hiddenFilter {
         display: none;
       }
     }
     .not-exist-profissional {
       color: #ccc;
       text-align: center;
       margin: 50px 0;
     }
  }
  .candidate-main{
    width: 100%;
    height: auto;
    min-height: 100vh;
    @media(max-width:768px){
      display: flex;
      flex-direction: column;
    }
  }
  header{
    width: 100%;
    height: 350px;
    background: #FDB00B;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media(max-width:768px){
      height: 380px;
    }
    h1{
      margin-top: 50px;
      color: #fff;
      text-transform: uppercase;
      text-align: center;
    }
    .toobar-wrapper{
      width: 100%;
      padding: 0 10%;
      position: absolute;
      bottom: 0;
      z-index: 999!important;
      @media(max-width:768px){
        padding: 0;
      }
    }
  }
  .candidate-container{
    width: 100%;
    height: auto;
    display: flex;
    @media(max-width:768px){
      flex-direction: column;
    }
  }
</style>
